import around from '../components/Animation/360.json';
import dayCar from '../components/Animation/foto_durante_o_dia.json';
import horizontal from '../components/Animation/foto_horizontal.json';
import tipAgricola from '../components/Animation/modal_tip_agricola.json';
import tipCarga from '../components/Animation/modal_tip_carga.json';
import tipMoto from '../components/Animation/modal_tip_moto.json';
import tipNautico from '../components/Animation/modal_tip_nautico.json';
import tipOnibus from '../components/Animation/modal_tip_onibus.json';
import tipParts from '../components/Animation/modal_tip_pecas.json';

const whichVehicle = (vehicle: any) => {
  switch (vehicle) {
    case 1:
      return dayCar;
    case 2:
      return tipMoto;
    case 3:
      return tipOnibus;
    case 4:
      return tipCarga;
    case 5:
      return tipNautico;
    case 6:
      return tipAgricola;
    case 7:
      return tipParts;
    default:
      break;
  }
};

const whichDescription = (vehicle: any) => {
  switch (vehicle) {
    case 1:
      return 'Mostre <b> todos os ângulos </b> do carro: frente, lateral, traseira e interior.';
    case 2:
      return 'Mostre <b> todos os ângulos </b> da moto: frente, lateral e traseira';
    case 3:
      return 'Mostre <b> todos os ângulos </b> do ônibus: frente, lateral, traseira e interior.';
    case 4:
      return 'Mostre <b> todos os ângulos </b> do caminhão: frente, lateral, traseira e interior.';
    case 5:
      return 'Mostre  <b> todos os ângulos </b> do veículo náutico: frente, lateral, traseira e interior.';
    case 6:
      return 'Mostre <b> todos os ângulos </b> do veículo agrícola: frente, lateral, traseira e interior.';
    case 7:
      return 'Mostre <b> todos os ângulos </b> da peça: frente, lateral, de cima.';
    default:
      break;
  }
};

export const modalTipsConfig = (vehicle: number | string) => [
  {
    id: 1,
    description:
      'Use a câmera na <b> horizontal</b>. Você terá mais espaço para mostrar o produto.',
    Animation: horizontal,
    icon: 'SvgIconOne',
  },
  {
    id: 2,
    description:
      'Tire as fotos <b> durante o dia</b>, em ambientes bem iluminados. A qualidade ficará melhor.',
    Animation: whichVehicle(vehicle),
    icon: 'SvgIconTwo',
  },
  {
    id: 3,
    description: whichDescription(vehicle),
    Animation: around,
    icon: 'SvgIconThree',
  },
];
export const itemsMenu = [
  {
    key: 'vitrine',
    textLink: 'Vitrine',
    linkTo: '#',
    submenu: false,
    icon: 'SvgIconArrowDown',
    submenuItems: [],
  },
  {
    key: 'comprar',
    textLink: 'Comprar',
    linkTo: '',
    submenu: true,
    icon: 'SvgIconArrowDown',
    submenuItems: [
      {
        id: 1,
        icon: 'SvgIconCar',
        textLink: 'Carros',
      },
      {
        id: 2,
        icon: 'SvgIconMotorcycle',
        textLink: 'Motos e Triciclos',
      },
      {
        id: 3,
        icon: 'SvgIconBus',
        textLink: 'Ônibus e Vans',
      },
      {
        id: 4,
        icon: 'SvgIconTruck',
        textLink: 'Caminhões e Tratores',
      },
      {
        id: 5,
        icon: 'SvgIconBoating',
        textLink: 'Barcos e Jet Skis',
      },
      {
        id: 6,
        icon: 'SvgIconServices',
        textLink: 'Peças e acessórios',
      },
    ],
  },
  {
    key: 'vender',
    textLink: 'Vender',
    linkTo: '',
    submenu: true,
    icon: 'SvgIconArrowDown',
    submenuItems: [
      {
        id: 1,
        icon: 'SvgIconCar',
        textLink: 'Carros',
      },
      {
        id: 2,
        icon: 'SvgIconMotorcycle',
        textLink: 'Motos e Triciclos',
      },
      {
        id: 3,
        icon: 'SvgIconBus',
        textLink: 'Ônibus e Vans',
      },
      {
        id: 4,
        icon: 'SvgIconTruck',
        textLink: 'Caminhões e Tratores',
      },
      {
        id: 5,
        icon: 'SvgIconBoating',
        textLink: 'Barcos e Jet Skis',
      },
    ],
  },
];

export const dataCategoryFilter = [
  {
    id: '1',
    iconName: 'SvgIconCar',
    label: 'Carros',
    listAllLinkLabel: 'Ver todos os carros',
  },
  {
    id: '2',
    iconName: 'SvgIconMotorcycle',
    label: 'Motos',
    listAllLinkLabel: 'Ver todas as motos',
  },
  {
    id: '4',
    iconName: 'SvgIconTruckSmall',
    label: 'Carga',
    listAllLinkLabel: 'Ver todos',
  },
  {
    id: '9',
    iconName: 'SvgIconStore',
    label: 'Lojas',
    listAllLinkLabel: 'Ver todas as lojas',
    link: '/lojas',
  },
  {
    id: '7',
    iconName: 'SvgIconServices',
    label: 'Serviços',
    listAllLinkLabel: 'Ver todos os serviços',
  },
  {
    id: '3',
    iconName: 'SvgIconBusSmall',
    label: 'Ônibus',
    listAllLinkLabel: 'Ver todos os ônibus',
  },
  {
    id: '5',
    iconName: 'SvgIconBoatSmall',
    label: 'Náutica',
    listAllLinkLabel: 'Ver todos',
  },
  {
    id: '6',
    iconName: 'SvgIconTractorSmall',
    label: 'Agrícola',
    listAllLinkLabel: 'Ver todos',
  },
  {
    id: '8',
    iconName: 'SvgIconParts',
    label: 'Peças',
    listAllLinkLabel: 'Ver todas as peças',
    // link: 'https://www.autoscar.com.br/anuncios/pecas-e-acessorios/',
  },
];

export const dataCategoryFilterHome = [
  {
    id: '1',
    iconName: 'SvgIconCar',
    label: 'Carros',
    listAllLinkLabel: 'Ver todos os carros',
  },
  {
    id: '2',
    iconName: 'SvgIconMotorcycle',
    label: 'Motos',
    listAllLinkLabel: 'Ver todas as motos',
  },
  // {
  //   id: '10',
  //   iconName: 'SvgIconPrivateCar',
  //   label: 'Particular',
  //   listAllLinkLabel: 'Ver todas os particulares',
  //   link: '/anuncios/busca/?categoria=carros&vendedor=particular',
  // },
  {
    id: '9',
    iconName: 'SvgIconStore',
    label: 'Lojas',
    listAllLinkLabel: 'Ver todas as lojas',
    link: '/lojas',
  },
  // {
  //   id: '4',
  //   iconName: 'SvgIconTruckSmall',
  //   label: 'Carga',
  //   listAllLinkLabel: 'Ver todos',
  // },
  // {
  //   id: '7',
  //   iconName: 'SvgIconServices',
  //   label: 'Serviços',
  //   listAllLinkLabel: 'Ver todos os serviços',
  // },
  // {
  //   id: '3',
  //   iconName: 'SvgIconBusSmall',
  //   label: 'Ônibus',
  //   listAllLinkLabel: 'Ver todos os ônibus',
  // },
  // {
  //   id: '5',
  //   iconName: 'SvgIconBoatSmall',
  //   label: 'Náutica',
  //   listAllLinkLabel: 'Ver todos',
  // },
  // {
  //   id: '6',
  //   iconName: 'SvgIconTractorSmall',
  //   label: 'Agrícola',
  //   listAllLinkLabel: 'Ver todos',
  // },
  {
    id: '8',
    iconName: 'SvgIconParts',
    label: 'Peças',
    listAllLinkLabel: 'Ver todas as peças',
  },
];
